<template>
  <div>
    <div class="title">
      2025年拟在豫招生普通高校专业选考科目概况
    </div>

    <div class="form">
      <div class="title_f">
        2025年拟在豫招生普通高校本科专业选考科目要求占比表（招生院校：{{ benkeCollegeNum }} 所 &nbsp; 招生专业：{{ benkeSubjectNum }}个）
      </div>
      <table class="table">
        <tr>
          <td width="80">序号</td>
          <td>科目</td>
          <td>要求</td>
          <td>（院校/专业）数量</td>
          <td>（院校/专业）占比</td>
        </tr>

        <tr v-for="(item, i) in benkeData" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.must_subject }}</td>
          <td>{{ item.remarks }}</td>
          <td>{{ item.num }}</td>
          <td>{{ item.scale }}%</td>
        </tr>
      </table>
    </div>


    <div class="form" style="margin-top: 40px;">
      <div class="title_f">
        2025年拟在豫招生普通高校高职高专专业选考科目要求占比表（招生院校：{{ zhuankeCollegeNum }} 所 &nbsp; 招生专业：{{ zhuankeSubjectNum }}个）
      </div>
      <table class="table">
        <tr>
          <td width="80">序号</td>
          <td>科目</td>
          <td>要求</td>
          <td>（院校/专业）数量</td>
          <td>（院校/专业）占比</td>
        </tr>

        <tr v-for="(item, i) in zhuankeData" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.must_subject }}</td>
          <td>{{ item.remarks }}</td>
          <td>{{ item.num }}</td>
          <td>{{ item.scale }}%</td>
        </tr>
      </table>
    </div>

    <div class="benzhu">
      备注：由于专科层次院校和专业大部分是不限制科目要求的，所以报考高手的选课系统主要针对本科层次
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      benkeData: [],
      zhuankeData: [],
      zhuankeCollegeNum: null,
      zhuankeSubjectNum: null,
      benkeCollegeNum: null,
      benkeSubjectNum: null,
    };
  },
 
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$fecth.post("xuanke/x_specialty/getData").then((res) => {
        this.benkeData = res.benke
        this.zhuankeData = res.zhuanke
        this.zhuankeCollegeNum = res.zhuankeCollegeNum
        this.zhuankeSubjectNum = res.zhuankeSubjectNum
        this.benkeCollegeNum = res.benkeCollegeNum
        this.benkeSubjectNum = res.benkeSubjectNum
      });
    }
  },
};
</script>

<style scoped lang='less'>
.title {
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}

.form {
  .title_f {
    text-align: center;
    line-height: 40px;
    background-image: linear-gradient(to right, #fef6eb, #ffcfb1);
  }

  .table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    td {
      border: 1px solid #d9d9d9;
      line-height: 40px;

    }
  }
}

.benzhu {
  margin-top: 20px;
  color: #949494;
}
</style>
